/**
 * @todo move QuoteProduct related things from `catalog` here
 */

import { gql } from '@apollo/client';
import type { Urgency } from '$util/types';

export interface Label {
  readonly value: string;
}

export interface Comment {
  readonly message: string;
  readonly updatedAt: string;
}

export interface TechnicalDescription {
  readonly urgency: Urgency;
  readonly description: string;
}

export interface QuoteProductAttributes {
  /**
   * eg. popular
   */
  readonly label?: Label;
  /**
   * Messages from the case manager to the customer
   */
  readonly comments?: Comment[];
  /**
   * Messages from the garage to the customer
   */
  readonly garageComments?: Comment[];
  /**
   * A detailed technical description typically used for MOT advisory comments.
   */
  readonly technicalDescription?: TechnicalDescription;
  /**
   * For advisories
   */
  readonly urgency?: Urgency;
}

export const FRAGMENT_QUOTE_PRODUCT_ATTRIBUTES = gql`
  fragment QuoteProductAttributesFragment on QuoteProductAttributes {
    label {
      value
    }
    comments {
      message
      updatedAt
    }
    garageComments {
      message
      updatedAt
    }
    technicalDescription {
      urgency
    }
  }
`;

export const FRAGMENT_QUOTE_PRODUCT = gql`
  fragment QuoteProductFragment on QuoteProduct {
    sku
    priceId
    name
    shortDescription
    longDescription
    price
    priceRange
    priceConfidence
    marketPrice
    isFinalPrice
    priceConfidence
    priceRange
    supported
    unsupportedReason
    lineItems {
      sku
      description
      total
      quantity
      category
    }
    attributes {
      ...QuoteProductAttributesFragment
    }
    labourTime
    searchTerms
  }
  ${FRAGMENT_QUOTE_PRODUCT_ATTRIBUTES}
`;
