import type { TypePolicies } from '@apollo/client';
import { gql } from '@apollo/client';
import {
  FRAGMENT_SHORT_TEXT_FIELD,
  FRAGMENT_CONTENT_FIELD,
  FRAGMENT_LONG_TEXT_FIELD,
} from '$apollo/gql/cms/contentful';

import { useProductPageCatalogItems, readProductPageCatalogItems } from './presentProducts';

export { useProductPageCatalogItems };

/**
 * Custom Apollo cache behavior
 */
export const productPageTypePolicies: TypePolicies = {
  Query: {
    fields: {
      readProductPageCatalogItems,
    },
  },
};

export const getCheckoutProductQuery = gql`
  query getCheckoutProductQuery($preview: Boolean, $locale: String!) {
    getCheckoutProductCmsContent: component(
      id: "6Pj3OsJ5CRXJpqvQBJeVi9"
      locale: $locale
      preview: $preview
    ) {
      entriesCollection {
        items {
          ...ShortTextField
          ...ContentField
        }
      }
    }
  }

  ${FRAGMENT_SHORT_TEXT_FIELD}
  ${FRAGMENT_CONTENT_FIELD}
`;

export const getCheckoutRepairsQuery = gql`
  query getCheckoutRepairsQuery($preview: Boolean, $locale: String!) {
    getCheckoutRepairsCmsContent: component(
      id: "2duVwe7P0iYBmi54ghUmNF"
      locale: $locale
      preview: $preview
    ) {
      entriesCollection {
        items {
          ...ShortTextField
          ...LongTextField
        }
      }
    }
  }

  ${FRAGMENT_SHORT_TEXT_FIELD}
  ${FRAGMENT_LONG_TEXT_FIELD}
`;
