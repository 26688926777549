/* eslint-disable no-underscore-dangle, import/no-cycle, global-require, prettier/prettier */
import type {
  PossibleTypesMap,
  NormalizedCacheObject} from '@apollo/client';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink
} from '@apollo/client';
import config from 'config';
import { merge } from 'lodash';
// eslint-disable-next-line import/no-cycle
import qs from 'qs';
import { errorLink } from '$apollo/link/error';
import { needsAuthenticationLink, waitAuthenticationLink } from '$apollo/link/custom';
import { sessionTypePolicies } from '$apollo/gql/session/vars';
// eslint-disable-next-line import/no-cycle
import { catalogTypePolicies } from '$apollo/gql/catalog/vars';
import { GlobalValue } from '$util/GlobalValue';
import { isBrowser } from '$util/index';
import type { AnyObject } from '$util/types';
import { productPageTypePolicies } from '$components/pages/checkout/quotes/productPage/gql';
import { schedulePageStateTypePolicies } from '$components/pages/checkout/schedule/gql/typePolicies';
import { ssrMainLayoutTypePolicies } from '$layout/MainLayout/gql/typePolicies';
import { cmsTypePolicies, CmsUnion } from '$cms/gql/typePolicies';
import { schedulePageTypeDefs } from '$components/pages/checkout/schedule/gql/index';
import { withHttpLinkLogger } from './httpLinkLogger';

/**
 * This is stored into global scope to be able to share it between page routes and API routes.
 */
const apolloClient = new GlobalValue<ApolloClient<NormalizedCacheObject>>('_APOLLO_CLIENT_INSTANCE_');

const httpLinkOptions = {
  /**
   * We add `operation` query param only for debug purposes.
   */
  uri: ({ operationName, getContext }: any) => {
    const context = getContext();
    if (context.source === 'Contentful') {
      return `https://graphql.contentful.com/content/v1/spaces/${config.get(
        'contentful.spaceId'
      )}/environments/master`;
    }

    const urlParams = qs.stringify({
      operation: operationName,
      /**
       * The context has to explicitly set `needsAuthentication: false`
       * in order to avoid checking the authentication token with this request.
       */
      needsAuthentication: context.needsAuthentication,
    });

    if (isBrowser) {
      return `/graph?${urlParams}`;
    }

    return `${config.get('public.graph_api')}?${urlParams}`;
  },
  credentials: 'include',
};

const httplink = new HttpLink(withHttpLinkLogger(httpLinkOptions));

const link = ApolloLink.from([needsAuthenticationLink, waitAuthenticationLink, errorLink as any, httplink]);

/**
 * Define custom cache behavior
 *
 * @todo split Apollo client into CSR client and SSR client to have a clear separation
 * and make it harder to have SSR code loaded to CSR by mistake.
 * @todo type policies for a specific page should be loaded only on that page.
 */
const getTypePolicies = (ssrMode = !isBrowser) => {
  /**
   * CSR & SSR Type Policies
   */
  const typePolicies = merge(
    {
      Booking: { keyFields: ['bookingUuid'] },
    },
    {
      QuoteLineItem: { keyFields: ['operationId'] },
    },
    {
      ProductGroup: { keyFields: ['groupId'] },
    },
    {
      Note: { keyFields: ['noteId'] },
    },
    sessionTypePolicies,
    catalogTypePolicies,
    productPageTypePolicies,
    schedulePageStateTypePolicies
  );

  /**
   * Only SSR Type Policies
   */
  if (ssrMode) {
    merge(typePolicies, ssrMainLayoutTypePolicies, cmsTypePolicies);
  }

  return typePolicies;
};

/**
 * Apollo Cache define union types
 */
const apolloCacheUnionTypes: PossibleTypesMap = {
  CatalogItem: ['QuoteProduct', 'ProductGroup'],
  ...CmsUnion,
};

const createApolloCache = (ssrMode = !isBrowser) =>
  new InMemoryCache({
    possibleTypes: apolloCacheUnionTypes,
    /**
     * Define custom cache behavior
     */
    typePolicies: getTypePolicies(ssrMode),
  });

export const createApolloClient = (ssrMode = !isBrowser): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    /**
     * Setting this to `false` makes all Apollo queries to be triggered SSR.
     * The only way to get control of this is to have this set to `true` when running on server
     * and specify on each Apollo query options `ssr: false`.
     *
     * Because we don't have any implementation of NextJS to wait for the result of SSR Apollo queries
     * and avoid side-effects (eg. delete auth token before setting cookie) we need to specify on each
     * Apollo query options `ssr: false` (at the time of implementation there's no way to make this the default)
     *
     * The implementation that waits for the result of SSR Apollo queries was avoided because at the time
     * of setting up Apollo with NextJS it was based on the deprecated `getInitialProps`.
     * @todo When we'll need Apollo queries to correctly run SSR find a suitable solution.
     *
     * IMPORTANT: not setting query options `ssr: false` + `startPolling()` option will create a poller
     * on server too which could lead to memory leaks created by multiple pollers running on server.
     * To avoid this mistake always start polling like so: `if (process.browser) startPolling(400);`
     */
    ssrMode,
    link,
    credentials: 'include',
    cache: createApolloCache(ssrMode),
    /**
     * Define local state type definitions
     */
    typeDefs: [schedulePageTypeDefs],
    connectToDevTools: !!process.env.NEXT_PUBLIC_LOCAL,
  });
};

export const initializeApollo = (initialState?: AnyObject): ApolloClient<NormalizedCacheObject> => {
  const ac = apolloClient.value ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state gets hydrated here
  if (initialState) {
    ac.cache.restore(initialState);
  }
  // Create one Apollo Client per browser and one global instance shared across users on server
  if (!apolloClient.value) {
    apolloClient.value = ac;
  }

  return ac;
};
