import { Locale, Segment } from '@fxtr/i18n';
import config from 'config';
import Cookies from 'js-cookie';

/**
 * This holds the state of feature flags that we use for TBD.
 *
 * Each flag will be manually added to this from config in order
 * to document it with what story it represents and what is the intention for it.
 */
export const features = {};

export const isFeatureSchedulePageOptionalCnDActive = (segment: Segment): boolean => {
  const local = segment === Segment.Fixter_FR ? Locale.FR_FR : Locale.EN_GB;
  return config.get<boolean>(`public.features.schedulePageOptionalCnD.${local}`);
};

export const isFeaturePriceMatchPromiseActive = (segment: Segment): boolean => {
  const local = segment === Segment.Fixter_FR ? Locale.FR_FR : Locale.EN_GB;
  return config.get<boolean>(`public.features.priceMatchPromise.${local}`);
};

/**
 * Kontiki: FR partner
 * Initial story: https://fixter.atlassian.net/browse/GRW-2476
 */
export const isKontikiTrackingActive = (local: Locale): boolean => {
  const partnerCookie = Cookies.get('fixterPartner');
  return partnerCookie === 'kontiki' && config.get<boolean>(`public.features.partners.kontiki.${local}`);
};

export const isFeatureContactUsFormActive = (local: Locale): boolean => {
  return config.get<boolean>(`public.features.contactUsForm.${local}`);
};
