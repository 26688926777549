import { message } from 'antd';
import type { ErrorResponse } from 'apollo-link-error';
import { onError } from 'apollo-link-error';
import { captureException, captureMessage, withScope } from '@sentry/nextjs';
import config from 'config';
import { pick, throttle } from 'lodash';
import qs from 'qs';
import { Segment } from '@fxtr/i18n';
import { changeUrl, getServerLogger, isBrowser } from '$util/index';
import { getSegmentFromClient } from '$util/getSegment';
// eslint-disable-next-line import/no-cycle
import type { ApolloErrorParsed } from '../util';
import { parseGQLError } from '../util';
import { SessionClientExpectedErrorCode } from '../gql/session';
import { IdentityClientExpectedErrorCode } from '../gql/identity';

let isChangingPage = false;
if (process.browser) {
  window.addEventListener('beforeunload', () => {
    isChangingPage = true;
  });
}

declare global {
  interface Window {
    hj: (...args: unknown[]) => void; // Hotjar
  }
}

const defaultErrorMessage = new Map([
  [Segment.Fixter_UK, "Something went wrong, please contact us if you're having difficulties using the app."],
  [
    Segment.Fixter_FR,
    'Un problème est survenu. Veuillez réessayer ou nous contacter si le problème persiste.',
  ],
]);

enum HttpStatus {
  Unauthorised = 401,
  Forbidden = 403,
}

interface ErrorExtraInfo extends Record<string, unknown> {
  operation?: Pick<ErrorResponse['operation'], 'operationName' | 'variables'>;
  errorTrackId?: string;
}

/**
 * Because we fire multiple fetches in parallel many of them can fail for the same reason
 * in which case we don't want to spam Sentry or display the same error message multiple times.
 * eg. getVehicle, getBasket, etc. failing with `Unauthorised` error.
 */
type ThrottleFn = ReturnType<typeof throttle>;
const throttleFn: Record<string, ThrottleFn> = {};
/**
 * Make sure to call the function referenced by `id` only once per second.
 */
const getThrottleFn = (id: string) => {
  if (typeof throttleFn[id] === 'function') return throttleFn[id];
  throttleFn[id] = throttle((fn: () => void) => fn(), 1000);
  return throttleFn[id];
};

const handleHttpStatus = (httpStatus: number, { operation, errorTrackId }: ErrorExtraInfo) => {
  const operationName = operation?.operationName || 'unknown';
  switch (httpStatus) {
    case HttpStatus.Unauthorised: {
      /**
       * If we see a 401 Unauthorised error, we should logout the customer and ask them
       * to re-authenticate themselves.
       */
      const redirect = `/error?${qs.stringify({
        reason: 'UNAUTHORIZED',
        operation: operationName,
        errorTrackId,
      })}`;
      changeUrl({ pathname: redirect });
      break;
    }
    case HttpStatus.Forbidden: {
      const redirect = `/error?${qs.stringify({
        reason: 'FORBIDDEN_ACCESS',
        operation: operationName,
        errorTrackId,
      })}`;
      changeUrl({ pathname: redirect });
      break;
    }
    default: {
      if (httpStatus >= 500) {
        /**
         * eg: Token expired
         */
        const redirect = `/error?${qs.stringify({
          reason: 'UNEXPECTED_ERROR',
          operation: operationName,
          errorTrackId,
        })}`;
        changeUrl({ pathname: redirect });
        break;
      }
    }
  }
};

const handleClientExpectedErrorCode = (restifyError: ApolloErrorParsed) => {
  const { clientExpectedErrorCode, info } = restifyError;
  switch (clientExpectedErrorCode) {
    case SessionClientExpectedErrorCode.SESSION_EXPIRED: {
      const { sessionId, vrm, postcode } = info || {};
      changeUrl({
        pathname: '/checkout/error/expired',
        query: { reason: clientExpectedErrorCode, sessionId, vrm, postcode },
      });
      break;
    }
    case IdentityClientExpectedErrorCode.TOKEN_EXPIRED: {
      const { sessionId } = info || {};
      changeUrl({
        pathname: '/checkout/error/expired',
        query: { reason: clientExpectedErrorCode, sessionId },
      });
      break;
    }
    default:
      break;
  }
};

const handleGraphQLErrors = (
  graphQLErrors: ErrorResponse['graphQLErrors'],
  operation: ErrorResponse['operation']
) => {
  const enabledSentry = config.get('public.sentry.enabled');
  const extra: ErrorExtraInfo = { operation: pick(operation, ['operationName', 'variables']) };
  if (!Array.isArray(graphQLErrors)) return;
  graphQLErrors.forEach((error) => {
    const restifyError = parseGQLError(error);
    if (restifyError.clientExpectedErrorCode) {
      /**
       * If it's an expected error (clientExpectedErrorCode) make sure to handle it in the UI.
       */
      handleClientExpectedErrorCode(restifyError);
      return;
    }
    /**
     * Log error
     */
    const errorName = `GraphQL error: ${operation.operationName}`; // for consistent group naming in Sentry
    extra.errorTrackId = Math.random().toString(16).substr(2, 8); // track error across OpsGenie, Sentry, Hotjar
    if (!isBrowser) {
      const log = getServerLogger('apollo');
      log.error({ error, extra }, errorName);
    } else {
      const { httpStatus } = restifyError;
      const throttleLog = getThrottleFn(String(httpStatus));
      throttleLog(() => {
        const segment = getSegmentFromClient();
        const errMsg = defaultErrorMessage.get(segment);
        // let user know something went wrong
        message.error(errMsg);
        if (enabledSentry) {
          // log error to Sentry
          withScope((scope) => {
            // group errors together based on the error name and httpStatus
            scope.setFingerprint([errorName, String(httpStatus)]);
            captureException(
              new Error(errorName), // set title correctly
              { extra: { ...extra, error } }
            );
          });
          // tag Hotjar session
          if (typeof window.hj === 'function') {
            window.hj('tagRecording', [`error_${extra.errorTrackId}`]);
          }
        }
        handleHttpStatus(httpStatus, extra);
      });
      console.error(errorName, { error, extra });
    }
  });
};

const handleNetworkError = (
  networkError: Required<ErrorResponse>['networkError'],
  operation: ErrorResponse['operation']
) => {
  if (isChangingPage || networkError.message === 'cancelled') {
    /**
     * Network errors are very common when we have pending requests and change page.
     * Since we have multiple pollers this happens a lot.
     * Don't log when changing page or `cancelled` request.
     */
    return;
  }

  const enabledSentry = config.get('public.sentry.enabled');
  const extra = { operation: pick(operation, ['operationName', 'variables']) };
  const errorName = `Network error: ${operation.operationName}`; // for consistent group naming in Sentry
  if (!process.browser) {
    const log = getServerLogger('apollo');
    log.warn({ error: networkError, extra }, errorName);
  } else {
    const throttleLog = getThrottleFn('networkError');
    throttleLog(() => {
      if (enabledSentry) {
        // log error to Sentry
        withScope((scope) => {
          // group errors together based on the error name
          scope.setFingerprint([errorName]);
          captureMessage(networkError.message, { level: 'warning', extra });
        });
      }
    });
    console.warn(errorName, {
      error: networkError,
      extra,
    });
  }
};

/**
 * Global error handler.
 */
export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    handleGraphQLErrors(graphQLErrors, operation);
  }
  if (networkError) {
    handleNetworkError(networkError, operation);
  }
});
