import type { FieldFunctionOptions } from '@apollo/client';
import type {
  CmsContentfulComponent,
  CmsContentfulImage,
  CmsContentfulLink,
  CmsContentfulShortText,
} from '$cms/contentful.types';
import { CmsContentfulTypename } from '$cms/contentful.types';
import type { ImageDetails } from '$cms/index';
import { getImageDetailsFromCmsImage } from '$cms/index';
import type { QueryGetFooterCmsContent } from '../../queries/getFooterCmsContent';
import { QUERY_GET_FOOTER_CMS_CONTENT } from '../../queries/getFooterCmsContent';

interface FooterData {
  readonly components: FooterComponentRecord;
  readonly copyright: string;
}

interface FooterComponentBase {
  readonly title: string;
}

const footerComponentWithLinksName = [
  'meetFixter',
  'joinUs',
  'popularServices',
  'terms',
  'garages',
  'callUs',
  'socialMedia',
] as const;
const footerComponentWithImagesName = ['paymentMethods'] as const;

export interface FooterComponentWithLinks extends FooterComponentBase {
  readonly name: (typeof footerComponentWithLinksName)[number];
  readonly links: FooterComponentLink[];
}

interface FooterComponentWithImages extends FooterComponentBase {
  readonly name: (typeof footerComponentWithImagesName)[number];
  readonly images: ImageDetails[];
}

type FooterComponentRecord = Record<FooterComponentWithLinks['name'], FooterComponentWithLinks> &
  Record<FooterComponentWithImages['name'], FooterComponentWithImages>;

export interface FooterComponentLink {
  readonly key: string;
  readonly text: string;
  readonly url: string;
}

const getFooterComponentImages = (component: CmsContentfulComponent): ImageDetails[] => {
  const {
    entriesCollection: { items },
  } = component;

  const images = items
    .filter((item): item is CmsContentfulImage => item.__typename === CmsContentfulTypename.image)
    .map(getImageDetailsFromCmsImage);

  return images;
};

const getFooterComponentLinks = (component: CmsContentfulComponent): FooterComponentLink[] => {
  const {
    entriesCollection: { items },
  } = component;

  const links = items
    .filter((item): item is CmsContentfulLink => item.__typename === CmsContentfulTypename.link)
    .map(({ key, text, url }) => ({ key, text, url }));

  return links;
};

const transformFooterComponents = (components: CmsContentfulComponent[]): FooterData['components'] =>
  components.reduce((o, component) => {
    const {
      entriesCollection: { items },
      name: componentName,
    } = component;

    const title =
      items.find(
        (item): item is CmsContentfulShortText =>
          item.__typename === CmsContentfulTypename.shortText && item.key === `${componentName}.title`
      )?.value || '';

    const name = componentName.split('.').pop() || '';
    const linkKey = footerComponentWithLinksName.find((key) => key === name);
    if (!linkKey) {
      const imageKey = footerComponentWithImagesName.find((key) => key === name);
      if (!imageKey) {
        /**
         * @todo really log the error
         */
        console.error('footer: component name unknown', componentName);
        return o;
      }
      const images = getFooterComponentImages(component);
      // eslint-disable-next-line no-param-reassign
      o[imageKey] = {
        name: name as FooterComponentWithImages['name'],
        title,
        images,
      };
      return o;
    }
    const links = getFooterComponentLinks(component);
    // eslint-disable-next-line no-param-reassign
    o[linkKey] = {
      name: name as FooterComponentWithLinks['name'],
      title,
      links,
    };
    return o;
  }, {} as FooterComponentRecord);

// TypePolicy types
export type ReadFooterDataOptions = FieldFunctionOptions<unknown>;
export type ReadFooterDataResponse = FooterData;

/**
 * TypePolicy that transform the footer CMS content and stores it into Apollo cache
 */
export const readFooterData = ({ cache, variables }: ReadFooterDataOptions): ReadFooterDataResponse => {
  const queryGetFooterCmsContent = cache.readQuery<QueryGetFooterCmsContent>({
    query: QUERY_GET_FOOTER_CMS_CONTENT,
    variables,
  });
  const componentCollectionItems = queryGetFooterCmsContent?.getFooterCmsContent.items;
  if (!componentCollectionItems) {
    throw new TypeError('Tried to read footer data before query CMS data');
  }

  const [
    {
      entriesCollection: { items },
    },
  ] = componentCollectionItems;

  const copyright =
    items.find((item): item is CmsContentfulShortText => item.__typename === CmsContentfulTypename.shortText)
      ?.value || '';
  const components = items.filter(
    (item): item is CmsContentfulComponent => item.__typename === CmsContentfulTypename.component
  );
  const footerData: FooterData = {
    components: transformFooterComponents(components),
    copyright,
  };

  return footerData;
};
