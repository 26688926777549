import type { TypePolicies } from '@apollo/client';
import type {
  ReadSchedulePageCardsStatusVariables,
  ReadSchedulePageCardsStatusOptions,
} from './readSchedulePageCardsStatus';
import { readSchedulePageCardsStatus } from './readSchedulePageCardsStatus';

export type { readSchedulePageCardsStatus, ReadSchedulePageCardsStatusVariables };

export const schedulePageStateTypePolicies: TypePolicies = {
  Query: {
    fields: {
      readSchedulePageCardsStatus: {
        read(_, options) {
          return readSchedulePageCardsStatus(options as ReadSchedulePageCardsStatusOptions);
        },
      },
    },
  },
};
