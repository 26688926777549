import { setContext } from '@apollo/client/link/context';
import { getAccessToken, changeUrl } from '$util/index';

const wait = 30000; // should be the same as for getSession poller

/**
 * Delay requests that need authentication until it's set by the session poller.\
 * In your query options set `context.waitAuthentication: true`.
 */
export const waitAuthenticationLink = setContext((request, context) => {
  const startTime = Date.now();
  return new Promise((resolve, reject) => {
    if (context.waitAuthentication) {
      const interval = setInterval(() => {
        if (getAccessToken()) {
          clearInterval(interval);
          resolve(context);
        } else if (Date.now() > startTime + wait) {
          clearInterval(interval);
          reject(new Error('exceeded authentication time'));
          changeUrl({
            pathname: '/error',
            query: { reason: 'AUTH_NOT_FOUND' },
          });
        }
      }, 400);
    } else resolve(context);
  });
});

/**
 * Redirect to login page if request needs authentication and it's missing.\
 * In your query options set `context.needsAuthentication: true`.
 */
export const needsAuthenticationLink = setContext(
  (request, context) =>
    new Promise((resolve, reject) => {
      if (context.needsAuthentication && !getAccessToken()) {
        reject(new Error('no authentication found'));
        changeUrl({
          pathname: `/login`,
          query: { redirect: encodeURIComponent(window.location.href) },
        });
      } else resolve(context);
    })
);
