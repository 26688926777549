import type { TypePolicies } from '@apollo/client';

interface ApolloCacheFieldRef {
  readonly __ref: string;
}

/**
 * Custom Apollo cache behavior
 * @note only SSR
 */
export const cmsTypePolicies: TypePolicies = {
  CmsContentComponent: { keyFields: ['id', 'locale'] },
  CmsPageContent: {
    fields: {
      /**
       * Because multiple page routes can belong to the same page template the data is normalized
       * by merging all page routes into an array on the corresponding CmsPageContent template.
       * But when we read data for a path, the client only needs one page route and the template data.
       *
       * @todo can we simplify this structure?
       *  - what are the memory implications if we would de-normalize given the big amounts of routes we have?
       *  - keep normalizing, but create a "cache-only" field that is just the page route needed by the client?
       */
      pageRoutes: {
        /**
         * Return only one page route corresponding to the path.
         */
        read(cmsPageRouteRefs: ApolloCacheFieldRef[], { readField, variables }) {
          if (!variables) {
            throw new TypeError('Expected variables in `CmsPageContent.pageRoutes` read type policy');
          }
          const cmsPageRouteRef = cmsPageRouteRefs.find((ref) => readField('path', ref) === variables.path);
          if (!cmsPageRouteRef) return [];
          return [cmsPageRouteRef];
        },
        /**
         * Normalize page routes under the corresponding template.
         * @link https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays
         */
        merge(existing: ApolloCacheFieldRef[] = [], incoming: ApolloCacheFieldRef[] = []) {
          return Array.from(new Set([...existing, ...incoming]));
        },
      },
    },
  },
};

/**
 * Apollo Cache possible types
 * @link https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
 */
export const CmsUnion = {
  CmsTranslatedEntity: ['CmsShortText', 'CmsLongText', 'CmsImage', 'CmsRichText', 'CmsLink', 'CmsNumber'],
};
