import type { ParsedUrlQuery } from 'querystring';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useRef, useCallback, useMemo } from 'react';
import Cookies from 'js-cookie';
import { message } from 'antd';
import type { QueryHookOptions, QueryResult } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { Segment } from '@fxtr/i18n';
import { changeUrl, logError } from '$util/index';
import { getApolloClient, parseApolloErrors } from '$apollo/util';
import { VEHICLE_ERROR_PAGE } from '$apollo/gql/vehicle';
import type { QuerySessionData, ISession } from './index';
import {
  QUERY_GET_SESSION,
  MUTATION_START_SESSION,
  FETCHED_SESSION_DATA,
  SessionClientExpectedErrorCode,
} from './index';

let maxPollerCount = 30;
const updatePollerCount = () => {
  if (maxPollerCount) maxPollerCount -= 1;
};

export const getSessionIdUrlQuery = (query: ParsedUrlQuery) => {
  const sessionId = query?.sessionId;
  if (!sessionId || typeof sessionId !== 'string') throw new Error('please provide sessionId');
  return sessionId;
};

/**
 * Store the poll of session query in context.
 * The state change will be used in queries that depend on session data.
 */
export const SessionContext = createContext<Omit<QueryResult, 'data'> & { data: ISession }>({} as any);

export interface SessionContextProviderProps {
  urlQuery: ParsedUrlQuery;
  children: ReactNode;
}

/**
 * Poll for session until it's complete and add it to session context to be consumed with `useSession`.
 * The polling will push data into context which in turn will trigger a rerender.
 *
 * @todo refactor to use same poller mechanism as `getCatalog` & `getVehicle`.
 */
export function SessionContextProvider({ urlQuery, children }: SessionContextProviderProps): JSX.Element {
  /**
   * Make sure to log only once while polling.
   */
  const logErrorOnce = useRef({
    exceedsPollingTries: false,
    noFixterSessionId: false,
  });

  const sessionId = getSessionIdUrlQuery(urlQuery);

  const queryOptions: QueryHookOptions<QuerySessionData> = {
    variables: { sessionId },
    ssr: false,
    // required to trigger callbacks after poll completes
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: updatePollerCount,
    onError: updatePollerCount,
  };

  const { data, error, ...rest } = useQuery<QuerySessionData>(QUERY_GET_SESSION, queryOptions);
  const { startPolling, stopPolling } = rest;
  if (process.browser) startPolling(1000);

  const session = useMemo(
    () =>
      data?.getSession || {
        sessionId,
        segment: (Cookies.get('fixterSegment') || Segment.Fixter_UK) as Segment,
      },
    [data?.getSession, sessionId]
  );

  // stop polling if all the data to fetch is in session or it has exceeded tries
  const isComplete = FETCHED_SESSION_DATA.every((k) => !!session[k]);
  const exceedsPollingTries = !maxPollerCount;
  if (isComplete || exceedsPollingTries) stopPolling();

  /**
   * Show error message only once when poller has exceeded tries.
   */
  if (exceedsPollingTries && !logErrorOnce.current.exceedsPollingTries) {
    let errMsg = 'Session poller exceeded tries';
    const extra: Record<string, unknown> = { session };
    if (error) {
      /**
       * Check if after all the polling finishes we still have NotFoundError and log it.
       */
      const errors = parseApolloErrors(error);
      const notFoundErr = errors.some(({ httpStatus }) => httpStatus === 404);
      if (notFoundErr) errMsg += ': Not found';
      extra.errors = errors;
    }

    logError(errMsg, extra);

    logErrorOnce.current.exceedsPollingTries = true;
    message.error(
      <>
        Something went wrong, please try again.
        <br />
        Contact us if you&apos;re still having difficulties using the app.
      </>,
      10
    );

    /**
     * Session creation retried a few times and it failed with errors.
     */
    if (session.errors?.length) {
      changeUrl({
        pathname: '/checkout/error/start',
        query: { reason: 'SESSION_CREATION', sessionId },
      });
    }
  }

  const sessionExpiredErrorCode = useMemo(() => {
    if (session.clientExpectedErrorCode) return session.clientExpectedErrorCode;
    if (error) {
      const { clientExpectedErrorCode } =
        parseApolloErrors(error).find((err) => err.clientExpectedErrorCode) || {};
      return clientExpectedErrorCode as SessionClientExpectedErrorCode;
    }
    return undefined;
  }, [error, session.clientExpectedErrorCode]);

  const handleSessionExpectedErrors = useCallback(
    (sessionClientExpectedErrorCode: SessionClientExpectedErrorCode) => {
      if (sessionClientExpectedErrorCode !== SessionClientExpectedErrorCode.SESSION_NOT_FOUND) {
        /**
         * We ignore NotFoundError because we'll get that on the first get of session
         * while it didn't had the time to create a new session yet.
         *
         * If it's another error make sure to not continue spamming multiple redirects.
         */
        stopPolling();
      }
      if (sessionClientExpectedErrorCode === SessionClientExpectedErrorCode.VEHICLE_NOT_FOUND) {
        changeUrl({
          pathname: VEHICLE_ERROR_PAGE,
          query: {
            reason: SessionClientExpectedErrorCode.VEHICLE_NOT_FOUND,
            vrm: session.vrm,
            postcode: session.postcode,
          },
        });
      }
      if (sessionClientExpectedErrorCode === SessionClientExpectedErrorCode.POSTCODE_DECODE_FAIL) {
        changeUrl({
          pathname: '/checkout/error/postcode',
          query: { vrm: session.vrm, postcode: session.postcode },
        });
      }
      if (sessionClientExpectedErrorCode === SessionClientExpectedErrorCode.POSTCODE_NOT_PROVIDED) {
        changeUrl({
          pathname: '/error',
          query: {
            reason: SessionClientExpectedErrorCode.POSTCODE_NOT_PROVIDED,
            vrm: session.vrm,
            postcode: session.postcode,
          },
        });
      }
    },
    [session.vrm, session.postcode, stopPolling]
  );

  useEffect(() => {
    /**
     * Handle session expected errors.
     */
    if (sessionExpiredErrorCode) handleSessionExpectedErrors(sessionExpiredErrorCode);
  }, [sessionExpiredErrorCode, handleSessionExpectedErrors]);

  const value = useMemo(() => ({ data: session, ...rest }), [rest, session]);

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
}

/**
 * Read session from context.
 * Use this with `useEffect` in order to know when to lazy query for data that is dependent on session.
 */
export const useSession = () => useContext(SessionContext);

/**
 * @todo call after submiting vrm
 * @param vrm
 */
export const startSession = (vrm: ISession['vrm']) => {
  const apolloClient = getApolloClient();
  return apolloClient.mutate({
    mutation: MUTATION_START_SESSION,
    variables: { vrm },
  });
};
