/* eslint-disable import/no-cycle */
import { useContext, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Segment } from '@fxtr/i18n';
import { pick, compact } from 'lodash';
import { useBooking } from '$apollo/gql/booking/hooks';
import { useTimeExceeded } from '$util/hooks/useTimeExceeded';
import { parseApolloErrors } from '$apollo/util';
import { AppContext } from '@/contexts/AppContext';
import { PollerStatus } from '../index';
import { catalogPollerStatusVar, catalogProductInfoVar } from './vars';
import type {
  QueryGetCatalogPoller,
  QueryGetCatalog,
  QueryGetCatalogVariables,
  Catalog,
  CatalogProductInfoMap,
  QuoteProduct,
  QuoteProductInfo,
  QueryReadCustomerProducts,
  QueryReadMotServicingProducts,
  QueryReadAllDisplayableProducts,
  QueryReadUpsellProducts,
} from './index';
import {
  CatalogTab,
  QUERY_GET_CATALOG_POLLER,
  QUERY_GET_CATALOG,
  CatalogSlug,
  QuoteProductInfoKeys,
  getFlatProducts,
  QUERY_READ_CUSTOMER_PRODUCTS,
  QUERY_READ_MOT_SERVICING_PRODUCTS,
  QUERY_READ_UPSELL_PRODUCTS,
  QUERY_READ_ALL_DISPLAYABLE_PRODUCTS,
} from './index';
// eslint-disable-next-line import/no-cycle

/**
 * We have different catalogs per segment, we need to be careful when fetching catalogs for another segment.
 * If we're fetching a catalog that doesn't contain products, the catalog poller will consider that not all prices are ready and
 * we won't be able to add products to the basket (see code ref: https://github.com/fxtr/landing-react/blob/b12d3a8cdf90ccf4a7173ceeab9029c62360f39c/apollo/gql/catalog/hooks.tsx#L126-L130)
 */
const CatalogCanBeEmpty = [CatalogSlug.UPSELL, CatalogSlug.CROSS_SELL];

const makeCatalogProductInfoMap = (catalogs: Catalog[]) =>
  catalogs.reduce<CatalogProductInfoMap>((map, catalog) => {
    const productsInfo = getFlatProducts(catalog.items).map<QuoteProductInfo>((item) =>
      pick(item, QuoteProductInfoKeys)
    );
    map.set(catalog.catalogSlug, productsInfo);
    return map;
  }, new Map());

const UK_CATALOGS = [
  CatalogSlug.CUSTOMER,
  CatalogSlug.MOT_SERVICING,
  CatalogSlug.DIAGNOSTICS,
  CatalogSlug.REPAIRS,
  CatalogSlug.MOST_POPULAR_REPAIRS,
  CatalogSlug.SEARCH,
  CatalogSlug.UPSELL,
  CatalogSlug.CROSS_SELL,
];

const FR_CATALOGS = [
  CatalogSlug.CUSTOMER,
  CatalogSlug.MOT_SERVICING,
  CatalogSlug.SERVICES,
  CatalogSlug.DIAGNOSTICS,
  CatalogSlug.REPAIRS,
  CatalogSlug.MOST_POPULAR_REPAIRS,
  CatalogSlug.SEARCH,
  CatalogSlug.UPSELL,
  CatalogSlug.CROSS_SELL,
];

export const mapCatalogSlugToActiveTab = (activeTab?: CatalogTab): CatalogSlug | undefined => {
  switch (activeTab) {
    case CatalogTab.DIAGNOSTICS:
      return CatalogSlug.DIAGNOSTICS;
    case CatalogTab.MOT:
      return CatalogSlug.MOT_SERVICING;
    case CatalogTab.SERVICES:
      return CatalogSlug.SERVICES;
    case CatalogTab.REPAIRS:
      return CatalogSlug.REPAIRS;
    default:
      return undefined;
  }
};

/**
 * We have different catalogs per segment, we need to be careful when fetching catalogs for another segment.
 * If we're fetching a catalog that doesn't contain products, the catalog poller will consider that not all prices are ready and
 * we won't be able to add products to the basket (see code ref: https://github.com/fxtr/landing-react/blob/b12d3a8cdf90ccf4a7173ceeab9029c62360f39c/apollo/gql/catalog/hooks.tsx#L126-L130)
 */
export const catalogVariablesWithSegment = (segment?: Segment, catalogSlug?: CatalogSlug) => {
  if (segment === Segment.Fixter_FR) {
    return {
      segment,
      catalogSlug,
      catalogs: FR_CATALOGS,
    };
  }
  return {
    segment,
    catalogSlug,
    catalogs: UK_CATALOGS,
  };
};

export const isRepairPage = (location: { pathname: string }): boolean => {
  const pathName: string = location?.pathname || '';
  return pathName.includes('/quotes/repairs');
};

// repairs final price is only needed when we are on a /repair page
export const canAcceptCatalogWithoutFinalPrice = (
  catalogSlug: CatalogSlug,
  activeTab?: CatalogTab
): boolean => {
  if (
    activeTab !== CatalogTab.REPAIRS &&
    [
      CatalogSlug.CUSTOMER,
      CatalogSlug.REPAIRS,
      CatalogSlug.MOST_POPULAR_REPAIRS,
      CatalogSlug.SEARCH,
    ].includes(catalogSlug)
  ) {
    return true;
  }
  return false;
};

export const hasAcceptablePrice = (
  { pricesAreFinal, catalogSlug }: Catalog,
  activeTab?: CatalogTab
): boolean => {
  if (pricesAreFinal) return true;
  if (canAcceptCatalogWithoutFinalPrice(catalogSlug, activeTab)) return true;
  return false;
};

/**
 * This just handles the polling.
 * Data will be returned using hook `useCatalog` by reading from the Apollo cache.
 */
export function useCatalogPoller(segment?: Segment, activeTab?: CatalogTab) {
  const exceededPollTime = useTimeExceeded(30000);
  const { data, startPolling, stopPolling, error } = useQuery<
    QueryGetCatalogPoller,
    QueryGetCatalogVariables
  >(QUERY_GET_CATALOG_POLLER, {
    variables: catalogVariablesWithSegment(segment, mapCatalogSlugToActiveTab(activeTab)),
    ssr: false,
    skip: !segment,
  });

  const catalogData = data?.getCatalog;
  const allPricesAreAcceptable =
    !!catalogData?.length && catalogData.every((catalog) => hasAcceptablePrice(catalog, activeTab));

  const errors = error ? parseApolloErrors(error) : [];

  if (errors.length) {
    console.error('getCatalog ERRORS', errors);
  }

  // Should poll for data when:
  const shouldPoll =
    // - still has time reserved for polling
    !exceededPollTime &&
    // - prices are not yet final
    !allPricesAreAcceptable &&
    // - no errors
    !errors.length;

  useEffect(() => {
    /**
     * Controls polling
     */
    if (shouldPoll) {
      startPolling(1000);
      catalogPollerStatusVar(PollerStatus.STARTED);
    }
    /**
     * This is counterintuitive but is required.
     * See doc: https://www.notion.so/fixter/Hooks-51af669f21a543dc8409f8cfe18177ae
     */
    return () => {
      if (shouldPoll) {
        stopPolling();
        catalogPollerStatusVar(PollerStatus.FINISHED);
      }
    };
  }, [shouldPoll, startPolling, stopPolling]);

  useEffect(() => {
    /**
     * Store the first result of catalogs product info with priceId from polling.
     * This will be used on places where we only need the product info
     * and don't want to re-render with the additional polling events for pricing.
     * eg. determine active tab
     * @todo find a way to do this in apollo cache
     */
    const catalogProductInfo = catalogProductInfoVar();

    const catalogsPriceIdReady = (catalogs: Catalog[]) =>
      catalogs
        // Add a filter here so CatalogProductInfoMap won't be empty, which prevents users from adding items to basket
        .filter(({ catalogSlug }) => !CatalogCanBeEmpty.includes(catalogSlug))
        .every(({ items }) => items.length && getFlatProducts(items).every((product) => product.priceId));
    if (!catalogProductInfo.size && !!catalogData?.length && catalogsPriceIdReady(catalogData)) {
      catalogProductInfoVar(makeCatalogProductInfoMap(catalogData));
    }
  }, [catalogData]);
}

/**
 * Return catalog data by reading only the cache
 * because the poller is responsible for fetching the data and updating the cache.
 */
export const useCatalog = () => {
  const { data: booking } = useBooking();
  const { activeTab: currentTab, location } = useContext(AppContext);
  const activeTab = isRepairPage(location) ? CatalogTab.REPAIRS : currentTab;

  const res = useQuery<QueryGetCatalog, QueryGetCatalogVariables>(QUERY_GET_CATALOG, {
    variables: catalogVariablesWithSegment(booking?.segment, mapCatalogSlugToActiveTab(activeTab)),
    fetchPolicy: 'cache-only',
    ssr: false,
    skip: !booking?.segment,
  });
  return res;
};

/**
 * Get all products that the customer has access to.
 */
export const useCustomerProducts = () => {
  const { data } = useQuery<QueryReadCustomerProducts>(QUERY_READ_CUSTOMER_PRODUCTS, {
    fetchPolicy: 'cache-only',
    ssr: false,
  });
  const products = useMemo(() => data?.readCustomerProducts ?? [], [data?.readCustomerProducts]);
  return products;
};

/**
 * Get all products belong to MOT_SERVICING catalog.
 */
export const useMotServicingProducts = () => {
  const { data } = useQuery<QueryReadMotServicingProducts>(QUERY_READ_MOT_SERVICING_PRODUCTS, {
    fetchPolicy: 'cache-only',
    ssr: false,
  });
  const products = useMemo(() => data?.readMotServicingProducts ?? [], [data?.readMotServicingProducts]);
  return products;
};

/**
 * Find specific products from cache.
 */
export const useProducts = (skus: QuoteProduct['sku'][]) => {
  const { data } = useQuery<QueryReadAllDisplayableProducts>(QUERY_READ_ALL_DISPLAYABLE_PRODUCTS, {
    fetchPolicy: 'cache-only',
    ssr: false,
  });
  const all = useMemo(() => data?.readAllDisplayableProducts ?? [], [data?.readAllDisplayableProducts]);
  const products = skus.map((s) => all.find((p) => p.sku === s));
  return compact(products);
};

/**
 * Find specific products from cache.
 */
export const useUpsellProducts = (skus: QuoteProduct['sku'][]) => {
  const { data } = useQuery<QueryReadUpsellProducts>(QUERY_READ_UPSELL_PRODUCTS, {
    fetchPolicy: 'cache-only',
    ssr: false,
  });
  const all = useMemo(() => data?.readUpsellProducts ?? [], [data?.readUpsellProducts]);
  const products = all.filter((p) => skus.includes(p.sku));
  return compact(products);
};
