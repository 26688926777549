import { gql } from '@apollo/client';
import { FRAGMENT_BASKET } from '$apollo/gql/basket';
import type { SchedulePageCardsStatus } from './typePolicies/readSchedulePageCardsStatus/SchedulePageCardsStatus';

export interface SchedulePageState {
  /**
   * @deprecated `cardStatus` is inferred from booking data
   */
  readonly cardsStatus: SchedulePageCardsStatus;
}

export interface QueryStateSchedulePage {
  readonly stateSchedulePage: SchedulePageState;
}

/**
 * This defines the structure of the local state
 * @deprecated `cardStatus` is inferred from booking data
 */
export const schedulePageTypeDefs = gql`
  type SchedulePageState {
    cardsStatus: SchedulePageCardsStatus
  }

  type SchedulePageCardsStatus {
    address: CardStatus
    collection: CardStatus
    delivery: CardStatus
  }

  extend type Query {
    stateSchedulePage: SchedulePageState!
  }
`;

/**
 * @deprecated `cardStatus` is inferred from booking data
 */
export const QUERY_STATE_SCHEDULE_PAGE = gql`
  query StateSchedulePage {
    stateSchedulePage {
      cardsStatus {
        address
        collection
        delivery
      }
      cardsStatusHistory {
        address
        collection
        delivery
      }
    }
  }
`;

export const MUTATION_SET_COLLECTION_AND_DELIVERY_SCHEDULE = gql`
  mutation setCollectionAndDeliverySchedule(
    $collectionDateTimeLocal: String!
    $collectionDeliveryOption: String!
    $deliveryDateTimeLocal: String!
    $bookingId: String!
    $tripPrice: Int!
    $addTrip: Boolean
  ) {
    setCollectionAndDeliverySchedule(
      collectionDateTimeLocal: $collectionDateTimeLocal
      collectionDeliveryOption: $collectionDeliveryOption
      deliveryDateTimeLocal: $deliveryDateTimeLocal
      bookingId: $bookingId
      tripPrice: $tripPrice
      addTrip: $addTrip
    ) {
      bookingUuid
      collectionDateTimeLocal
      collectionDeliveryOption
      collectionTimeSlot
      deliveryDateTimeLocal
      deliveryTimeSlot
      # We need to re-fetch the basket to get display prices
      # TODO: We should be able to do this without re-fetching the basket
      ...Basket
    }
  }
  ${FRAGMENT_BASKET}
`;

export const MUTATION_ADD_COLLECTION_AND_DELIVERY_TO_BASKET = gql`
  mutation addCollectionAndDeliveryToBasket(
    $bookingId: String!
    $collectionDeliveryOption: String!
    $tripPrice: Int!
    $transportMethodOptions: [String!]
  ) {
    addCollectionAndDeliveryToBasket(
      bookingId: $bookingId
      collectionDeliveryOption: $collectionDeliveryOption
      tripPrice: $tripPrice
      transportMethodOptions: $transportMethodOptions
    ) {
      bookingUuid
      collectionDeliveryOption
      # We need to re-fetch the basket to get display prices
      # TODO: We should be able to do this without re-fetching the basket
      ...Basket
    }
  }
  ${FRAGMENT_BASKET}
`;

export const MUTATION_SET_BOOKING_TRANSPORT_METHOD = gql`
  mutation setBookingTransportMethod(
    $bookingId: String!
    $transportMethod: String!
    $transportMethodOptions: [String!]
  ) {
    setBookingTransportMethod(
      bookingId: $bookingId
      transportMethod: $transportMethod
      transportMethodOptions: $transportMethodOptions
    ) {
      bookingUuid
      collectionDeliveryOption
    }
  }
`;

export const MUTATION_SET_BOOKING_ORIGINAL_DATE_TIME = gql`
  mutation setOriginalDateTime($bookingUuid: String!) {
    setOriginalDateTime(bookingUuid: $bookingUuid) {
      bookingUuid
      originalCollectionDateTime
      originalDeliveryDateTime
    }
  }
`;

export const MUTATION_SET_BOOKING_GARAGE = gql`
  mutation setBookingGarage($bookingId: String!, $garageId: String!) {
    setBookingGarage(bookingId: $bookingId, garageId: $garageId) {
      bookingUuid
      garageId
      garage {
        garageId
        addressLine1
        addressLine2
        addressLine3
        city
        county
        postcode
      }
    }
  }
`;

export const MUTATION_UPDATE_SESSION = gql`
  mutation updateSession($sessionId: String!, $experiments: [String!]) {
    updateSession(sessionId: $sessionId, experiments: $experiments) {
      sessionId
    }
  }
`;

export const MUTATION_ADD_BOOKING_TAG = gql`
  mutation addBookingTag($bookingUuid: String!, $bookingNumber: Int!, $tag: String!) {
    addBookingTag(bookingUuid: $bookingUuid, bookingNumber: $bookingNumber, tag: $tag) {
      bookingId
      bookingUuid
    }
  }
`;
