import { gql } from '@apollo/client';

export const FRAGMENT_IMAGE_FIELD = gql`
  fragment ImgField on Image {
    key
    img: value
    alt
    title
  }
`;

export const FRAGMENT_SHORT_TEXT_FIELD = gql`
  fragment ShortTextField on ShortText {
    key
    value
  }
`;

export const FRAGMENT_LONG_TEXT_FIELD = gql`
  fragment LongTextField on LongText {
    key
    value
  }
`;

export const FRAGMENT_CONTENT_FIELD = gql`
  fragment ContentField on ContentTypeRichText {
    key
    content: value {
      json
    }
  }
`;

export const FRAGMENT_LINK_FIELD = gql`
  fragment LinkField on KbAppLink {
    key
    text
    url
  }
`;

export const FRAGMENT_SUB_COMPONENT = gql`
  fragment SubComponent on Component {
    name
    entriesCollection {
      items {
        __typename
        ...ImgField
        ...ShortTextField
        ...LongTextField
        ...LinkField
        ...ContentField
      }
    }
  }

  ${FRAGMENT_LINK_FIELD}
`;
