import { gql } from '@apollo/client';
import type { Segment } from '@fxtr/i18n';

export enum SessionClientExpectedErrorCode {
  SESSION_NOT_FOUND = 'NOT_FOUND',
  VEHICLE_NOT_FOUND = 'VEHICLE_NOT_FOUND',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  POSTCODE_DECODE_FAIL = 'POSTCODE_DECODE_FAIL',
  POSTCODE_NOT_PROVIDED = 'POSTCODE_NOT_PROVIDED',
}

export enum SessionErrorReason {
  START_SESSION = 'START_SESSION',
  SESSION_CREATION = 'SESSION_CREATION',
  MISSING_VRM = 'MISSING_VRM',
  MISSING_POSTCODE = 'MISSING_POSTCODE',
  MISSING_VRM_AND_POSTCODE = 'MISSING_VRM_AND_POSTCODE',
}

// @todo try using public-session model
export interface ISession {
  readonly sessionId: string;
  readonly vrm?: string;
  readonly segment: Segment;
  /**
   * The initial lookup postcode.
   * NOTE: this could be different than the one on booking if it's changed on a later state.
   */
  readonly postcode?: string;
  readonly customerId?: string;
  readonly vehicleId?: string;
  readonly campaignId?: string;

  /**
   * Postcode coordinated decoded into lat/lng (if API is successful)
   */
  readonly latitude?: number;
  readonly longitude?: number;
  /**
   * It's booking UUID
   */
  readonly bookingId?: string;
  readonly priceListSessionId?: string;
  /**
   * Store it on the booking when available.
   */
  readonly partner?: string;
  /**
   * AB tests
   */
  readonly experiments?: string[];
  readonly sourceQuoteId?: string;
  /**
   * Used to retrigger a request that will remove
   * the access token from the session after the cookie was set
   */
  readonly wasReceivedByClient?: boolean;
  /**
   * Errors from create session while retrying.
   */
  errors?: string[];
  /**
   * Errors that should be handled by the client in a special way.
   * eg. Vehicle not found should send to vehicle error page.
   */
  clientExpectedErrorCode?: SessionClientExpectedErrorCode;
}

export interface QuerySessionData {
  getSession: ISession;
}

export const QUERY_GET_SESSION = gql`
  query getSession($sessionId: String!) {
    getSession(sessionId: $sessionId) {
      sessionId
      vrm
      segment
      postcode
      vehicleId
      customerId
      campaignId
      priceListSessionId
      bookingId
      experiments
      wasReceivedByClient
      clientExpectedErrorCode
      errors
    }
  }
`;

export interface MutationStartSessionData {
  startSession: ISession;
}

export const MUTATION_START_SESSION = gql`
  mutation startSession(
    $vrm: String!
    $postcode: String!
    $segment: String
    $partner: String
    $experiments: [String!]
    $campaignId: String
    $latitude: Float
    $longitude: Float
    $collectionType: String
    $isGarageMembership: Boolean
    $garageId: String
    $sourceQuoteId: String
  ) {
    startSession(
      vrm: $vrm
      postcode: $postcode
      segment: $segment
      partner: $partner
      experiments: $experiments
      campaignId: $campaignId
      latitude: $latitude
      longitude: $longitude
      collectionType: $collectionType
      isGarageMembership: $isGarageMembership
      garageId: $garageId
      sourceQuoteId: $sourceQuoteId
    ) {
      sessionId
    }
  }
`;

export const FETCHED_SESSION_DATA: (keyof ISession)[] = [
  'vehicleId',
  'customerId',
  'priceListSessionId',
  'bookingId',
  'wasReceivedByClient',
];
