import { useMemo } from 'react';
import type { FieldReadFunction } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import type { CatalogItem } from '$apollo/gql/catalog';
import { FRAGMENT_CATALOG_ITEM, getCatalogItemSlug, isQuoteProduct } from '$apollo/gql/catalog';

interface QueryReadProductPageCatalogItems {
  readonly readProductPageCatalogItems: CatalogItem[];
}

interface QueryReadProductPageCatalogItemsVariables {
  readonly catalogItemSlug: string | string[];
}

const QUERY_PRODUCT_PAGE_PRESENT_PRODUCTS = gql`
  query ReadProductPageCatalogItems {
    readProductPageCatalogItems @client {
      ...CatalogItemFragment
    }
  }
  ${FRAGMENT_CATALOG_ITEM}
`;

/**
 * Get catalog items that will be displayed on the product page by slug.
 */
export const useProductPageCatalogItems = (
  catalogItemSlug: QueryReadProductPageCatalogItemsVariables['catalogItemSlug']
): CatalogItem[] => {
  const { data } = useQuery<QueryReadProductPageCatalogItems, QueryReadProductPageCatalogItemsVariables>(
    QUERY_PRODUCT_PAGE_PRESENT_PRODUCTS,
    {
      variables: { catalogItemSlug },
      fetchPolicy: 'cache-only',
      ssr: false,
    }
  );
  const products = useMemo(
    () => data?.readProductPageCatalogItems ?? [],
    [data?.readProductPageCatalogItems]
  );
  return products;
};

/**
 * Apollo cache type policy.
 * Get catalog items that will be displayed on the product page by slug.
 */
export const readProductPageCatalogItems: FieldReadFunction<unknown> = (_, { readField, variables }) => {
  const displayableProducts = readField<CatalogItem[]>('readDisplayableProducts') ?? [];
  const { catalogItemSlug } = variables as QueryReadProductPageCatalogItemsVariables;
  if (Array.isArray(catalogItemSlug)) {
    return displayableProducts.filter((item) => isQuoteProduct(item) && catalogItemSlug.includes(item.sku));
  }
  return displayableProducts.filter((item) => getCatalogItemSlug(item) === catalogItemSlug);
};
