import type { TypePolicies } from '@apollo/client';
import { makeVar } from '@apollo/client';
// eslint-disable-next-line import/no-cycle
import Cookies from 'js-cookie';
import { Segment } from '@fxtr/i18n';
import { getApolloClient } from '$apollo/util';
import { PollerStatus } from '../index';
// eslint-disable-next-line import/no-cycle
import { catalogVariablesWithSegment } from './hooks';
import type { Catalog, CatalogProductInfoMap, QueryGetCatalog, QuoteProduct } from './index';
import { CatalogSlug, isProductGroup, isQuoteProduct, QUERY_GET_CATALOG } from './index';

/**
 * @deprecated use getSegmentFromClient
 */
const getSegmentFromCookie = () => (Cookies.get('fixterSegment') || Segment.Fixter_UK) as Segment;

/**
 * Used to store the first result of catalog products id (sku, priceId) from polling.
 * This will be used on places where we only need the product info
 * and don't want to re-render with the additional polling events for pricing.
 * eg. determine active tab
 */
export const catalogProductInfoVar = makeVar<CatalogProductInfoMap>(new Map());

/**
 * Indicate the poller status as it changes.
 */
export const catalogPollerStatusVar = makeVar<PollerStatus>(PollerStatus.NOT_STARTED);

/**
 * Custom Apollo cache behavior
 */
export const catalogTypePolicies: TypePolicies = {
  Catalog: { keyFields: ['catalogId'] },
  QuoteProduct: {
    keyFields: ['sku', 'priceId'],
    fields: {
      price: {
        /**
         * Wait until we have the final prices or when the poller has finished before displaying the price
         */
        read(price, { readField }) {
          const isFinalPrice = readField('isFinalPrice');
          const catalogPollerStatus = catalogPollerStatusVar();
          return isFinalPrice || catalogPollerStatus === PollerStatus.FINISHED ? price : null;
        },
      },
    },
  },
  Query: {
    fields: {
      getCatalog: {
        read(catalogs) {
          // for the `QUERY_GET_CATALOG` to work correctly reading from the cache
          return catalogs ?? [];
        },
      },
      catalogPollerStatus: {
        read() {
          return catalogPollerStatusVar();
        },
      },

      /**
       * Get specific catalog by slug.
       */
      readCatalog: {
        read(_, { variables }) {
          const client = getApolloClient();
          const segment = getSegmentFromCookie();
          // used `readQuery` because `readField` didn't work for this query.
          const res = client.readQuery<QueryGetCatalog>({
            query: QUERY_GET_CATALOG,
            variables: catalogVariablesWithSegment(segment, variables?.catalogSlug),
          });
          const catalogs = res?.getCatalog ?? [];
          return catalogs.find(({ catalogSlug }) => catalogSlug === variables?.catalog);
        },
      },

      /**
       * Get all products in MOT_SERVICING catalog.
       */
      readMotServicingProducts: {
        read(_, { readField }) {
          const motServicingCatalog = readField<Catalog>({
            fieldName: 'readCatalog',
            variables: {
              catalog: CatalogSlug.MOT_SERVICING,
            },
          });
          return motServicingCatalog?.items?.filter(isQuoteProduct) ?? [];
        },
      },

      /**
       * Get all products in DIAGNOSTICS catalog.
       */
      readDiagnosticsProducts: {
        read(_, { readField }) {
          const diagnosticsCatalog = readField<Catalog>({
            fieldName: 'readCatalog',
            variables: {
              catalog: CatalogSlug.DIAGNOSTICS,
            },
          });
          return diagnosticsCatalog?.items?.filter(isQuoteProduct) ?? [];
        },
      },

      /**
       * Get all products in REPAIRS catalog.
       */
      readRepairsProducts: {
        read(_, { readField }) {
          const repairsCatalog = readField<Catalog>({
            fieldName: 'readCatalog',
            variables: {
              catalog: CatalogSlug.REPAIRS,
            },
          });
          return repairsCatalog?.items?.filter(isQuoteProduct) ?? [];
        },
      },

      /**
       * Get all products that the customer has access to.
       * All items should be `QuoteProduct` type from `customer` catalog.
       * @todo not needed when we have basket service
       */
      readCustomerProducts: {
        read(_, { readField }) {
          const customerCatalog = readField<Catalog>({
            fieldName: 'readCatalog',
            variables: {
              catalog: CatalogSlug.CUSTOMER,
            },
          });
          return customerCatalog?.items?.filter(isQuoteProduct) ?? [];
        },
      },

      /**
       * Get all products in UPSELL catalog.
       */
      readUpsellProducts: {
        read(_, { readField }) {
          const upsellCatalog = readField<Catalog>({
            fieldName: 'readCatalog',
            variables: {
              catalog: CatalogSlug.UPSELL,
            },
          });
          return upsellCatalog?.items?.filter(isQuoteProduct) ?? [];
        },
      },

      /**
       * Displayable products: available in CUSTOMER catalog + all product groups from REPAIRS catalog.
       */
      readDisplayableProducts: {
        read(_, { readField }) {
          const customerProducts = readField<QuoteProduct[]>('readCustomerProducts') ?? [];
          const repairsCatalog = readField<Catalog>({
            fieldName: 'readCatalog',
            variables: {
              catalog: CatalogSlug.REPAIRS,
            },
          });

          const productGroups = repairsCatalog?.items?.filter(isProductGroup) || [];
          return [...productGroups, ...customerProducts];
        },
      },

      /**
       * Get all products in REPAIRS catalog, MOT_SERVICING catalog, DIAGNOSTICS catalog & CUSTOMER catalog.
       */
      readAllDisplayableProducts: {
        read(_, { readField }) {
          const motServicingProducts = readField<QuoteProduct[]>('readMotServicingProducts') ?? [];
          const diagnosticsProducts = readField<QuoteProduct[]>('readDiagnosticsProducts') ?? [];
          const displayableProducts = readField<QuoteProduct[]>('readDisplayableProducts') ?? [];
          return [...motServicingProducts, ...displayableProducts, ...diagnosticsProducts];
        },
      },
    },
  },
};
