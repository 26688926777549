/**
 * A class to facilitate working with global values.
 * eg.
 * ```
 * const myGlobalVar = new GlobalValue('myGlobalVar');
 * // set value
 * myGlobalVar.value = 'global value';
 * // read value
 * myGlobalVar.value;
 * ```
 *
 * @warn Usually it's a bad practice to use global vars, think twice before using this.
 */
export class GlobalValue<T> {
  private readonly globalVar = global as Record<symbol, T | undefined>;

  private readonly sym: symbol;

  constructor(uniqueName: string) {
    this.sym = Symbol.for(uniqueName);
  }

  get value(): T | undefined {
    return this.globalVar[this.sym];
  }

  set value(value: T | undefined) {
    this.globalVar[this.sym] = value;
  }
}
