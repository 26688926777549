import type { HttpOptions } from '@apollo/client';
import { isBrowser, getLogger } from '$util/index';

const safeParse = (val: unknown) => {
  try {
    return JSON.parse(val as string);
  } catch {
    return val;
  }
};

export function httpLinkLoggerFactory(): (input: RequestInfo | URL, init?: RequestInit) => Promise<Response> {
  const log = getLogger('httpLinkLogger');
  return async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
    let url: URL;
    if (input instanceof URL) {
      url = input;
    } else if (typeof input === 'string') {
      url = new URL(input);
    } else {
      url = new URL(input.url);
    }

    const body = safeParse(init?.body || '{}');

    const start = Date.now();
    try {
      log.debug(`Sending ${body.operationName} request...`);
      const response = await fetch(input, init);
      log.debug(
        { duration: Date.now() - start, target: url.hostname, operation: body.operationName },
        `Received ${body.operationName} response`
      );

      return {
        ...response,
        async text() {
          const startT = Date.now();
          try {
            const result = await response.text();
            log.debug(
              {
                duration: Date.now() - startT,
                bytes: result.length,
                target: url.hostname,
                operation: body.operationName,
              },
              `Parsed ${body.operationName} response body`
            );
            return result;
          } catch (err) {
            log.error(
              {
                err,
                duration: Date.now() - startT,
                target: url.hostname,
                operation: body.operationName,
              },
              `Error parsing body from ${body.operationName}`
            );
            throw err;
          }
        },
      };
    } catch (err) {
      log.error(
        {
          err,
          duration: Date.now() - start,
          target: url.hostname,
          operation: body.operationName,
        },
        `Error from ${body.operationName}`
      );
      throw err;
    }
  };
}

export function withHttpLinkLogger(httpLinkOptions: HttpOptions): HttpOptions {
  if (isBrowser) {
    return httpLinkOptions;
  }
  return {
    ...httpLinkOptions,
    fetch: httpLinkLoggerFactory(),
  };
}
