import { gql } from '@apollo/client';
import type { Segment } from '@fxtr/i18n';
import type { Urgency } from '$util/types';
import type { PRODUCT_TYPES } from '../product';
import type { IBooking, IAddress, TransportStatus, GarageStatus } from '../booking';
import { FRAGMENT_VEHICLE } from '../vehicle';
// eslint-disable-next-line import/no-cycle
import type { ICredit } from '../customer';
import type { QuoteProductId } from '../catalog';
import type { IPayment } from '../Payment';
import type { IVehicle } from '../vehicle/types';

export const PROMOTION_TYPES = ['discount', 'coupon'] as const;
export const PROMOTION_CREDIT_TYPES = [...PROMOTION_TYPES, 'credit'] as const;
export const COLLECTION_DELIVERY_TYPE = 'c&d';

export interface ISubLineItem {
  readonly sku?: string;
  readonly title?: string;
  readonly operationId?: number;
  readonly fixedCostWithVAT?: number;
  readonly category?: string;
  readonly partsQuantity?: number;
}

export interface Comment {
  readonly type: string;
  readonly message?: string;
}

export interface Metadata {
  readonly linkingId?: string;
}

export type WorkStatus = 'approved' | 'declined' | 'draft' | 'reviewing' | 'approving';

export interface ILineItem {
  readonly operationId: number;
  readonly sku?: string;
  readonly source?: string; // priceId
  readonly title: string;
  readonly type:
    | (typeof PRODUCT_TYPES)[number]
    | (typeof PROMOTION_CREDIT_TYPES)[number]
    | typeof COLLECTION_DELIVERY_TYPE;
  readonly fixedCost: number;
  readonly fixedCostWithVAT: number;
  readonly marketingPrice?: number;
  readonly vatExempt: boolean;
  readonly subLineItems?: ISubLineItem[];
  // promotion dependencies
  readonly requiredItems?: string[];
  readonly affectedItems?: string[];
  readonly order: number;
  readonly metadata?: Metadata;
  readonly urgency?: Urgency;
  readonly workStatus?: WorkStatus;
  readonly comments?: Comment[];
}

export interface IQuote {
  readonly quoteId: string;
  readonly customerFinalVAT: number;
  readonly customerTotalPriceWithVAT: number;
  readonly customerTotalMarketingPrice: number;
  readonly customerGoodsTotalWithVAT: number;
  readonly lineItems: ILineItem[];
}

export interface IGarage {
  readonly garageId: string;
  readonly businessName: string;
  readonly addressLine1: string;
  readonly addressLine2?: string;
  readonly addressLine3?: string;
  readonly county?: string;
  readonly city?: string;
  readonly latitude?: number;
  readonly longitude?: number;
  readonly postcode: string;
  readonly email?: string;
  readonly phoneNumber?: string;
}

export interface BasketFragment {
  readonly bookingUuid: IBooking['bookingUuid'];
  readonly bookingId: IBooking['bookingId'];
  readonly quote: IQuote;
}

export interface QueryGetBasket {
  readonly getBooking: BasketFragment;
  readonly getCredit: ICredit;
}

export interface MutationModifyBasket {
  readonly modifyBasket: BasketFragment;
}

export interface MutationApplyPromotion {
  readonly applyPromotion: BasketFragment;
}

export interface MutationApplyCredit {
  readonly applyCredit: BasketFragment;
}

export interface IBasketData {
  /**
   * We want to have booking UUID as the primary identifier
   * that's why in most cases (outside of results from graphql)
   * `bookingUuid` will be referenced as `bookingId`.
   */
  readonly bookingId: IBooking['bookingUuid'];
  readonly quoteId: IQuote['quoteId'];
  readonly totalPrice: IQuote['customerTotalPriceWithVAT'];
  readonly totalMarketingPrice: IQuote['customerTotalMarketingPrice'];
  readonly items: IQuote['lineItems'];
  readonly credit: ICredit;
  readonly digitalServiceSheet?: IBooking['digitalServiceSheet'];
  readonly invoice?: IBooking['invoice'];
  readonly transportStatus?: TransportStatus;
  readonly garageStatus?: GarageStatus;
}

export type IBasketDataByBookingId = IBasketData & Omit<IBookingView, 'bookingId' | 'bookingUuid' | 'quote'>;

export const RemoveBasketItemKeys = ['sku', 'source'] as const;
export type RemoveBasketItem = Pick<ILineItem, (typeof RemoveBasketItemKeys)[number]>;

export interface ModifyBasketItems {
  add: QuoteProductId[];
  remove: RemoveBasketItem[];
}

export const FRAGMENT_BASKET = gql`
  fragment Basket on Booking {
    bookingUuid
    bookingId
    quote {
      quoteId
      customerTotalPriceWithVAT
      customerTotalMarketingPrice
      customerGoodsTotalWithVAT
      lineItems {
        operationId
        sku
        source
        title
        type
        fixedCost
        fixedCostWithVAT
        marketingPrice
        vatExempt
        subLineItems {
          operationId
          title
          fixedCostWithVAT
          sku
          category
          partsQuantity
        }
        requiredItems
        affectedItems
      }
    }
  }
`;

export const QUERY_GET_BASKET = gql`
  query getBasket($bookingId: String!) {
    getBooking(bookingId: $bookingId) {
      ...Basket
    }
    getCredit {
      balance
      used
    }
  }
  ${FRAGMENT_BASKET}
`;

export const MUTATION_MODIFY_BASKET = gql`
  mutation modifyBasket($modify: ModifyBasketInput!) {
    modifyBasket(modify: $modify) {
      ...Basket
    }
  }
  ${FRAGMENT_BASKET}
`;

export const MUTATION_APPLY_PROMOTION = gql`
  mutation applyPromotion($bookingId: String!, $code: String!) {
    applyPromotion(bookingId: $bookingId, code: $code) {
      ...Basket
    }
  }
  ${FRAGMENT_BASKET}
`;

export const MUTATION_APPLY_CREDIT = gql`
  mutation applyCredit($bookingId: String!, $amount: Float!) {
    applyCredit(bookingId: $bookingId, amount: $amount) {
      ...Basket
    }
  }
  ${FRAGMENT_BASKET}
`;

export interface IQueryGetBasketById {
  readonly getBooking: IBookingView;
  readonly getCredit: ICredit;
}

export type VehicleData = Pick<
  IVehicle,
  'vrm' | 'vehicleId' | 'make' | 'model' | 'litre' | 'logo' | 'fuelType' | 'dateRegistered'
>;

export interface IBookingView {
  readonly bookingId: IBooking['bookingId'];
  readonly bookingUuid: IBooking['bookingUuid'];
  readonly quote: IQuote;
  readonly vehicle: VehicleData;
  readonly collectionTimeSlot?: IBooking['collectionTimeSlot'];
  readonly deliveryTimeSlot?: IBooking['deliveryTimeSlot'];
  readonly collectionDeliveryOption?: IBooking['collectionDeliveryOption'];
  readonly collectionDateTimeLocal: IBooking['collectionDateTimeLocal'];
  readonly collectionAddressId?: IBooking['collectionAddressId'];
  readonly deliveryDateTimeLocal: IBooking['deliveryDateTimeLocal'];
  readonly deliveryAddressId?: IBooking['deliveryAddressId'];
  readonly masterStatusId: IBooking['masterStatusId'];
  readonly collectionAddress: IAddress;
  readonly deliveryAddress: IAddress;
  readonly proposedWorkOperations: IQuote;
  readonly garage: IGarage;
  readonly collectionNote?: string;
  readonly payment?: IPayment;
  readonly segment: Segment;
}

export const FRAGMENT_ADDRESS = gql`
  fragment AddressFields on Address {
    addressId
    postcode
    line1
    line2
    line3
    city
    county
    latitude
    longitude
  }
`;

export const QUERY_GET_BOOKING_BY_ID = gql`
  query getBooking($bookingId: String!) {
    getBooking(bookingId: $bookingId) {
      collectionTimeSlot
      deliveryTimeSlot
      masterStatusId
      segment
      vehicle {
        ...VehicleFields
      }
      collectionDeliveryOption
      collectionDateTimeLocal
      deliveryDateTimeLocal
      collectionAddress {
        ...AddressFields
      }
      deliveryAddress {
        ...AddressFields
      }
      collectionNote
      digitalServiceSheet {
        signedUrl
      }
      invoice {
        signedUrl
      }
      ...Basket
      proposedWorkOperations {
        quoteId
        lineItems {
          title
          operationId
        }
      }
      garage {
        garageId
        businessName
        addressLine1
        addressLine2
        addressLine3
        county
        city
        postcode
        latitude
        longitude
      }
      payment {
        status
      }
      transportStatus
      garageStatus
    }
  }
  ${FRAGMENT_ADDRESS}
  ${FRAGMENT_VEHICLE}
  ${FRAGMENT_BASKET}
`;

export const QUERY_GET_TRACKING_PAGE_BOOKING_BY_ID = gql`
  query getBooking($bookingId: String!) {
    getBooking(bookingId: $bookingId) {
      customerId
      collectionTimeSlot
      deliveryTimeSlot
      masterStatusId
      segment
      tags
      vehicle {
        ...VehicleFields
      }
      collectionDeliveryOption
      collectionDateTimeLocal
      deliveryDateTimeLocal
      collectionAddress {
        ...AddressFields
      }
      deliveryAddress {
        ...AddressFields
      }
      collectionNote
      digitalServiceSheet {
        signedUrl
      }
      ...Basket
      proposedWorkOperations {
        quoteId
        lineItems {
          title
          operationId
        }
      }
      garage {
        garageId
        businessName
        addressLine1
        addressLine2
        addressLine3
        county
        city
        postcode
        latitude
        longitude
        totalYrsOfExperience
        totalBookingsCompletedWithFixter
        closingHour
      }
      payment {
        status
      }
      transportStatus
      garageStatus
      trips {
        collection {
          etaStart
          etaEnd
          leg1TrackingLink
          leg2TrackingLink
        }
        delivery {
          etaStart
          etaEnd
          leg1TrackingLink
          leg2TrackingLink
        }
      }
    }
  }
  ${FRAGMENT_ADDRESS}
  ${FRAGMENT_VEHICLE}
  ${FRAGMENT_BASKET}
`;
