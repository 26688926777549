import { useEffect, useRef, useState } from 'react';

/**
 * @deprecated use `useTimeout()` instead
 *
 * Checks when time is exceeded with the number of `ms` starting from when we pass a positive value for `ms`.
 * To set start time later set `ms` to 0 initially;
 */
export const useTimeExceeded = (ms: number, interval = 1000): boolean => {
  const startTimeRef = useRef<number>(0);
  const [exceededTime, setExceededTime] = useState(false);

  useEffect(() => {
    let checkInterval: NodeJS.Timeout;
    if (ms) {
      startTimeRef.current = Date.now();
      checkInterval = setInterval(() => {
        const hasExceededTime = Date.now() > startTimeRef.current + ms;
        if (hasExceededTime) {
          clearInterval(checkInterval);
          setExceededTime(true);
        }
      }, interval);
    }
    return () => clearInterval(checkInterval);
  }, [ms, interval]);

  return exceededTime;
};
