import { gql } from '@apollo/client';
import type { IVehicle } from './types';

export const VEHICLE_ERROR_PAGE = '/checkout/error/vehicle';
export const GENERIC_ERROR_PAGE = '/error';

export interface QueryGetVehicle {
  readonly getVehicle: IVehicle;
}

export const FRAGMENT_VEHICLE = gql`
  fragment VehicleFields on Vehicle {
    vehicleId
    vrm
    make
    model
    fuelType
    litre
    logo
    motExpiryDate
    nextMotDate
    nextTaxDate
    dateRegistered
    vehicleAge
    motCompletedDateTime
    lastEstimatedMiles
    reasonUnsupported
  }
`;

export const QUERY_GET_VEHICLE = gql`
  query getVehicle($vehicleId: String!) {
    getVehicle(vehicleId: $vehicleId) {
      ...VehicleFields
    }
  }
  ${FRAGMENT_VEHICLE}
`;

export interface MutationCreateElectricVehicleLeadRequest {
  createLeadRequest: {
    email: string;
  };
}

export const MUTATION_CREATE_ELECTRIC_VEHICLE_LEAD_REQUEST = gql`
  mutation createElectricVehicleLeadRequest($leadInput: LeadInputGql!) {
    createLeadRequest(leadInput: $leadInput) {
      email
    }
  }
`;

export const MUTATION_CAPTURE_VEHICLE_MILEAGE = gql`
  mutation captureVehicleMileage($mileage: Float!, $unit: String!, $vehicleId: String!) {
    captureVehicleMileage(mileage: $mileage, unit: $unit, vehicleId: $vehicleId)
  }
`;
