import type { WatchQueryFetchPolicy } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
// eslint-disable-next-line import/no-cycle
import { useSession } from '$apollo/gql/session/hooks';
import type {
  IQueryGetBookingData,
  IQueryGetLastBookingPostcodeData,
  MutationCreateOfflineQuoteRequest,
} from './index';
import {
  QUERY_GET_BOOKING,
  QUERY_GET_LAST_BOOKING_POSTCODE,
  MUTATION_CREATE_OFFLINE_QUOTE_REQUEST,
} from './index';

/**
 * Hook to get the last postcode the customer booked with
 * We don't need this data if we already have it in the fixterPostcode cookie
 */
export const useLastBookingPostcode = () => {
  const lastPostcode = Cookies.get('fixterPostcode');
  const { data, ...rest } = useQuery<IQueryGetLastBookingPostcodeData>(QUERY_GET_LAST_BOOKING_POSTCODE, {
    ssr: false,
    skip: !!lastPostcode,
  });
  return { data: data?.getLastBookingPostcode, ...rest };
};

/**
 * Hook to get booking
 */
export const useBooking = (fetchPolicy?: WatchQueryFetchPolicy) => {
  const { data: session } = useSession();
  const { customerId, bookingId } = session || {};
  const { data, ...rest } = useQuery<IQueryGetBookingData>(QUERY_GET_BOOKING, {
    variables: { bookingId: session?.bookingId },
    skip: !customerId || !bookingId,
    notifyOnNetworkStatusChange: true,
    context: {
      waitAuthentication: true,
    },
    fetchPolicy: fetchPolicy || 'cache-first', // 'cache-first' is the default
  });
  return { data: data?.getBooking, ...rest };
};

/**
 * Hook to create an offline quote request
 */
export const useCreateOfflineQuoteRequest = () => {
  const [mutationCreateOfflineQuoteRequest, res] = useMutation<MutationCreateOfflineQuoteRequest>(
    MUTATION_CREATE_OFFLINE_QUOTE_REQUEST
  );
  const createOfflineQuoteRequest = (
    sessionId: string,
    skus: string[],
    vrm: string,
    postcode: string | undefined,
    comments: string,
    email: string,
    mobile: string,
    name: string,
    type: string
  ) =>
    mutationCreateOfflineQuoteRequest({
      variables: {
        leadInput: {
          sessionId,
          skus,
          vrm,
          postcode,
          comments,
          email,
          mobile,
          name,
          type,
        },
      },
    });
  return [createOfflineQuoteRequest, res] as const;
};

interface CreateOutOfCoverageLeadRequestParams {
  sessionId: string;
  skus: (string | undefined)[];
  quoteId: string | undefined;
  postcode: string | undefined;
  email: string;
  type: string;
  vrm: string | undefined;
}

export const useCreateOutOfCoverageLeadRequest = () => {
  const [mutationCreateOfflineQuoteRequest, res] = useMutation<MutationCreateOfflineQuoteRequest>(
    MUTATION_CREATE_OFFLINE_QUOTE_REQUEST
  );
  const createOutOfCoverageLeadRequest = ({
    sessionId,
    skus = [],
    quoteId,
    postcode,
    email,
    type,
    vrm,
  }: CreateOutOfCoverageLeadRequestParams) =>
    mutationCreateOfflineQuoteRequest({
      variables: {
        leadInput: {
          sessionId,
          skus,
          quoteId,
          postcode,
          email,
          type,
          vrm,
        },
      },
    });
  return [createOutOfCoverageLeadRequest, res] as const;
};

/**
 * Hook to save quote (receive quote by email)
 */
export const useSaveQuoteRequest = () => {
  const [mutationCreateOfflineQuoteRequest, res] = useMutation<MutationCreateOfflineQuoteRequest>(
    MUTATION_CREATE_OFFLINE_QUOTE_REQUEST
  );
  const createSaveQuoteRequest = (
    sessionId: string,
    vrm: string,
    postcode: string,
    skus: string[],
    email: string,
    quoteId: string,
    type: string
  ) =>
    mutationCreateOfflineQuoteRequest({
      variables: {
        leadInput: {
          skus,
          sessionId,
          vrm,
          postcode,
          email,
          quoteId,
          type,
        },
      },
    });
  return [createSaveQuoteRequest, res] as const;
};
