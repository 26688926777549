import type { GraphQLError, GraphQLFormattedError } from 'graphql';
import type { ApolloClient, ApolloError, NormalizedCacheObject, StoreValue } from '@apollo/client';
// eslint-disable-next-line import/no-cycle
import { get, set } from 'lodash';
import { createApolloClient, initializeApollo } from '$util/apollo/client';
import type { AnyObject } from '$util/types';

export * from './util/declareGQL';

export const getApolloClient = (): ApolloClient<NormalizedCacheObject> => initializeApollo();

/**
 * Debug Apollo Cache SSR data using ApolloClientDevTools
 */
export const debugApolloClientDevToolsSsrCacheData = (): void => {
  try {
    const apolloCacheSsrData = JSON.parse(get(window, '__APOLLO_CACHE_SSR_DATA__.innerHTML', '{}'));
    const apolloClientSSR = createApolloClient(true);
    apolloClientSSR.cache.restore(apolloCacheSsrData);
    set(window, '__APOLLO_CLIENT__', apolloClientSSR); // enables ApolloClientDevTools
  } catch (error) {
    console.error('Unable to debug Apollo Cache SSR data', error);
  }
};

export const extractApolloCacheData = (): NormalizedCacheObject => {
  const apolloClient = getApolloClient();
  return apolloClient.cache.extract();
};

export const invalidateApolloCacheData = (obj: {
  readonly __typename: string;
  [storeFieldName: string]: StoreValue;
}): boolean => {
  const apolloClient = getApolloClient();
  const evictDone = apolloClient.cache.evict({
    id: apolloClient.cache.identify(obj),
  });
  if (evictDone) apolloClient.cache.gc();
  return evictDone;
};

/**
 * A restify-errors exception as an apollo error response.
 */
export interface ApolloErrorException extends GraphQLError {
  httpStatus?: number;
  code?: string;
  message: string;
  // eslint-disable-next-line camelcase
  info?: {
    clientExpectedErrorCode?: string;
    [key: string]: unknown;
  };
  statusCode: number;
}

export interface ApolloErrorParsed extends Error {
  httpStatus: number;
  code: string;
  message: string;
  /**
   * Intended error that should give some feedback to the user.
   */
  clientExpectedErrorCode?: string;
  info?: AnyObject;
}

/**
 * Make apollo GraphQLError with restify-errors exceptions more easy to work with.
 */
export const parseGQLError = (error: GraphQLError): ApolloErrorParsed => {
  const exception: ApolloErrorException = error as unknown as ApolloErrorException;
  const { clientExpectedErrorCode, ...info } = exception?.info || {};
  return {
    name: exception.name,
    httpStatus: exception?.httpStatus || 0,
    code: exception?.code || 'Unknown',
    message: exception?.message || 'Unknown error',
    clientExpectedErrorCode,
    info,
  };
};

/**
 * Parse all graphQL errors from Apollo error and ignore network errors.
 */
export const parseApolloErrors = (apolloError: ApolloError): ApolloErrorParsed[] =>
  apolloError.graphQLErrors &&
  apolloError.graphQLErrors.map((error: GraphQLFormattedError) => parseGQLError(error as GraphQLError));
