import { gql } from '@apollo/client';
import type { Segment } from '@fxtr/i18n';

export enum BookingStatus {
  DRAFT = 500,
  UNALLOCATED = 10000,
  PENDING = 10100,
  ALLOCATED = 10200,
  AWAITING_PICKUP = 10300,
  BEING_COLLECTED = 10400,
  AT_GARAGE = 10600,
  WORK_COMPLETE = 10700,
  PAID = 10800,
  CLOSED = 11000,
  CANCELLED = 20000,
  GARAGE_DECLINED = 20100,
}

export enum TransportStatus {
  WITH_CUSTOMER = 'WITH_CUSTOMER',
  BEING_COLLECTED = 'BEING_COLLECTED',
  TRANSPORT_TO_GARAGE = 'TRANSPORT_TO_GARAGE',
  AT_GARAGE = 'AT_GARAGE',
  DELIVERED = 'DELIVERED',
  BEING_RETURNED = 'BEING_RETURNED',
  CANCELLED = 'CANCELLED',
  COLLECTION_FAILED = 'COLLECTION_FAILED',
  AWAITING_PICKUP = 'AWAITING_PICKUP',
  AWAITING_RETURN = 'AWAITING_RETURN',
}

export enum GarageStatus {
  CUSTOMER_REVIEW = 'CUSTOMER_REVIEW',
  GARAGE_DRAFT = 'GARAGE_DRAFT',
  FIXTER_REVIEW = 'FIXTER_REVIEW',
  WAITING_FOR_VEHICLE = 'WAITING_FOR_VEHICLE',
  WORK_STARTED = 'WORK_STARTED',
  WORK_COMPLETE = 'WORK_COMPLETE',
}

export enum CollectionDeliveryOption {
  GARAGE = 'GarageC&D',
  CUSTOMER = 'CustomerC&D',
  FIXTER = 'Fixter',
}

type DigitalServiceSheet = {
  signedUrl: string;
};

type Invoice = {
  signedUrl: string;
};

export interface IBooking {
  // NOTE: does not include `quote`, do `useBasket` for `quote` data
  readonly bookingId: number;
  readonly bookingUuid: string;
  readonly vehicleId?: string;
  readonly garageId?: string;
  readonly locationId?: string;
  readonly collectionTimeSlot?: number;
  readonly deliveryTimeSlot?: number;
  readonly collectionDeliveryOption?: string;
  readonly collectionDateTimeLocal?: string;
  readonly collectionAddressId?: number;
  readonly deliveryDateTimeLocal?: string;
  readonly deliveryAddressId?: number;
  readonly campaignId?: string;
  readonly sourceQuoteId?: string;
  readonly quoteId?: string;
  readonly notes?: Record<string, string>[];
  readonly masterStatusId: number;
  readonly digitalServiceSheet?: DigitalServiceSheet;
  readonly invoice?: Invoice;
  readonly referenceId?: string;
  readonly segment: Segment;
  readonly transportStatus?: string;
  readonly garageStatus?: string;
  readonly tags?: string;
}

export interface IAddress {
  readonly addressId: number;
  readonly line1?: string;
  readonly line2?: string;
  readonly line3?: string;
  readonly city?: string;
  readonly county?: string;
  readonly postcode: string;
  readonly latitude: string;
  readonly longitude: string;
  readonly display?: string;
}

export interface IQueryGetBookingData {
  readonly getBooking: IBooking;
}

export interface IQueryGetLastBookingPostcodeData {
  readonly getLastBookingPostcode: string;
}

export const QUERY_GET_BOOKING = gql`
  query getBooking($bookingId: String!) {
    getBooking(bookingId: $bookingId) {
      bookingUuid
      bookingId
      vehicleId
      garageId
      locationId
      collectionDeliveryOption
      collectionDateTimeLocal
      collectionTimeSlot
      deliveryTimeSlot
      collectionAddressId
      deliveryDateTimeLocal
      deliveryAddressId
      campaignId
      masterStatusId
      sourceQuoteId
      quoteId
      referenceId
      garageId
      segment
      tags
    }
  }
`;

export const QUERY_GET_LAST_BOOKING_POSTCODE = gql`
  query getLastBookingPostcode {
    getLastBookingPostcode
  }
`;

export interface MutationCreateOfflineQuoteRequest {
  createLeadRequest: {
    sessionId: string;
  };
}

export const MUTATION_CREATE_OFFLINE_QUOTE_REQUEST = gql`
  mutation createLeadRequest($leadInput: LeadInputGql!) {
    createLeadRequest(leadInput: $leadInput) {
      sessionId
    }
  }
`;
