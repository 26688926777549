import { gql } from '@apollo/client';
import type { Segment } from '@fxtr/i18n';
import type { QuoteProductAttributes } from '$apollo/gql/QuoteProduct';
import { FRAGMENT_QUOTE_PRODUCT } from '$apollo/gql/QuoteProduct';
import type { PollerStatus } from '..';

export const isQuoteProduct = (item: CatalogItem): item is QuoteProduct => item.__typename === 'QuoteProduct';
export const isProductGroup = (item: CatalogItem): item is ProductGroup => item.__typename === 'ProductGroup';

export const isProposedProduct = (item: CatalogItem): boolean =>
  isQuoteProduct(item) &&
  !!item.attributes.technicalDescription &&
  !!item.attributes.technicalDescription.urgency &&
  !!item.attributes.comments;

export const hasProposedProduct = (items: CatalogItem[]): boolean => items.some(isProposedProduct);

export const isSavedProduct = (item: CatalogItem): boolean => {
  if (isQuoteProduct(item)) return !!item.priceId?.includes('EMAIL-QUOTE-');
  if (isProductGroup(item))
    return item.products.some((product) => product.priceId && product.priceId.includes('EMAIL-QUOTE-'));
  return false;
};

export const getCatalogItemKey = (item: CatalogItem) =>
  isQuoteProduct(item) ? `${item.priceId || item.sku}` : item.groupId;

export const getCatalogItemSlug = (item: CatalogItem) => (isQuoteProduct(item) ? item.sku : item.groupSlug);

export const getCatalogItemSearchTerms = (item: CatalogItem) =>
  isQuoteProduct(item) ? item.searchTerms : '';

export const getFlatProducts = (catalogItems: Catalog['items']) =>
  catalogItems.flatMap((item) => {
    if (isProductGroup(item)) return item.products;
    return item;
  });

export enum CatalogSlug {
  CUSTOMER = 'customer',
  MOT_SERVICING = 'mot-servicing',
  SERVICES = 'services',
  DIAGNOSTICS = 'diagnostics',
  REPAIRS = 'repairs',
  MOST_POPULAR_REPAIRS = 'most-popular-repairs',
  SEARCH = 'customer-search',
  UPSELL = 'upsell',
  CROSS_SELL = 'cross-sell',
}

/**
 * Quotes page tabs
 */
export enum CatalogTab {
  MOT = 'MOT',
  SERVICES = 'SERVICES',
  DIAGNOSTICS = 'DIAGNOSTICS',
  REPAIRS = 'REPAIRS',
}
export const isCatalogTab = (tab: string): tab is CatalogTab =>
  Object.values<string>(CatalogTab).includes(tab);

/**
 * Assign catalogs to quotes page tab
 */
export const CatalogSlugTab: Partial<Record<CatalogSlug, CatalogTab>> = {
  [CatalogSlug.CUSTOMER]: CatalogTab.REPAIRS,
  [CatalogSlug.MOT_SERVICING]: CatalogTab.MOT,
  [CatalogSlug.SERVICES]: CatalogTab.SERVICES,
  [CatalogSlug.DIAGNOSTICS]: CatalogTab.DIAGNOSTICS,
  [CatalogSlug.REPAIRS]: CatalogTab.REPAIRS,
  [CatalogSlug.MOST_POPULAR_REPAIRS]: CatalogTab.REPAIRS,
  [CatalogSlug.SEARCH]: CatalogTab.REPAIRS,
  [CatalogSlug.UPSELL]: CatalogTab.REPAIRS,
};

export interface QuoteProductLineItem {
  /**
   * @deprecated
   */
  readonly title?: string;
  /**
   * @deprecated
   */
  readonly workSummary?: string;
  readonly sku?: string;
  readonly description?: string;
  readonly total?: number;
  readonly category?: string;
  readonly quantity?: number;
}

/**
 * Product with prices when ready
 */
export interface QuoteProduct {
  readonly __typename: 'QuoteProduct';
  readonly sku: string;
  readonly priceId: string;
  readonly name: string;
  readonly categories?: string;
  readonly shortDescription?: string;
  readonly longDescription?: string;
  readonly unsupportedReason?: string;
  /**
   * The API returns `null` sometimes (usually when we have `priceRange`)
   * and `undefined` other times (usually when the price is not ready yet).
   */
  readonly price?: number | null;
  readonly priceRange?: [number, number];
  readonly priceConfidence: number;
  readonly marketPrice?: number;
  readonly supported: boolean;
  readonly isFinalPrice: boolean;
  readonly lineItems: QuoteProductLineItem[];
  readonly attributes: QuoteProductAttributes;
  readonly labourTime?: string;
  readonly groupSlug?: string;
  readonly catalogSlug?: string;
  readonly searchTerms?: string;
}

export const QuoteProductIdKeys = ['sku', 'priceId'] as const;
export type QuoteProductId = Pick<QuoteProduct, (typeof QuoteProductIdKeys)[number]>;

/**
 * Fields that will be there from the start without having to wait for additional polling.
 */
export const QuoteProductInfoKeys = [
  ...QuoteProductIdKeys,
  'name',
  'categories',
  'shortDescription',
  'longDescription',
] as const;

/**
 * QuoteProduct info that will be there from the start without having to wait for additional polling.
 */
export type QuoteProductInfo = Pick<QuoteProduct, (typeof QuoteProductInfoKeys)[number]>;

/**
 * Groups multiple products into one view
 * eg. group Services
 */
export interface ProductGroup {
  readonly __typename: 'ProductGroup';
  readonly groupId: string;
  readonly groupSlug: string;
  readonly name: string;
  readonly shortDescription?: string;
  readonly longDescription?: string;
  /**
   * When all prices from `products` have `isFinalPrice: true`
   */
  readonly pricesAreFinal: boolean;
  readonly explanation?: string;
  readonly products: QuoteProduct[];
}

/**
 * A catalog of products and product groups
 * eg. mot, diagnostic, repairs tabs
 */
export interface Catalog {
  readonly catalogId: string; // random unique id
  readonly catalogSlug: CatalogSlug; // unique url friendly id for referencing catalogs manually.
  readonly catalogName: string; // for example: Mot & Service
  readonly shortDescription?: string; // for example: empty for now
  readonly longDescription?: string; // for example: empty for now
  /**
   * When all prices from `items` have `isFinalPrice: true`
   */
  readonly pricesAreFinal: boolean;
  readonly items: CatalogItem[];
}

export type CatalogItem = QuoteProduct | ProductGroup;

export type CatalogProductInfoMap = Map<CatalogSlug, QuoteProductInfo[]>;

export const FRAGMENT_CATALOG_ITEM = gql`
  fragment CatalogItemFragment on CatalogItem {
    __typename
    ... on QuoteProduct {
      ...QuoteProductFragment
    }
    ... on ProductGroup {
      groupId
      groupSlug
      name
      shortDescription
      longDescription
      pricesAreFinal
      explanation
      products {
        ...QuoteProductFragment
      }
    }
  }
  ${FRAGMENT_QUOTE_PRODUCT}
`;

export const FRAGMENT_CATALOG = gql`
  fragment CatalogFragment on Catalog {
    catalogId
    catalogSlug
    catalogName
    pricesAreFinal
    items {
      ...CatalogItemFragment
    }
  }
  ${FRAGMENT_CATALOG_ITEM}
`;
export interface QueryGetCatalogVariables {
  readonly segment?: Segment;
  readonly catalogs: CatalogSlug[];
  readonly catalogSlug?: CatalogSlug;
}
export interface QueryGetCatalogPoller {
  readonly getCatalog: Catalog[];
}

export const QUERY_GET_CATALOG_POLLER = gql`
  query GetCatalogPoller($catalogs: [String!]!, $segment: String, $catalogSlug: String) {
    getCatalog(catalogs: $catalogs, segment: $segment, catalogSlug: $catalogSlug) {
      ...CatalogFragment
    }
  }
  ${FRAGMENT_CATALOG}
`;

export interface QueryGetCatalog {
  readonly getCatalog: Catalog[];
  readonly catalogPollerStatus: PollerStatus;
}

/**
 * Get catalog data from Apollo cache and the status of polling.
 * NOTE: don't include polling status in `QUERY_GET_CATALOG_POLLER`
 * because it will cause additional renders for the polling hook when it's value changes.
 */
export const QUERY_GET_CATALOG = gql`
  query GetCatalog($catalogs: [String!]!, $segment: String, $catalogSlug: String) {
    getCatalog(catalogs: $catalogs, segment: $segment, catalogSlug: $catalogSlug) {
      ...CatalogFragment
    }
    catalogPollerStatus @client
  }
  ${FRAGMENT_CATALOG}
`;

export interface QueryReadCustomerProducts {
  readonly readCustomerProducts: QuoteProduct[];
}

/**
 * Get all products that the customer has access to.
 */
export const QUERY_READ_CUSTOMER_PRODUCTS = gql`
  query ReadCustomerProducts {
    readCustomerProducts @client {
      ...QuoteProductFragment
    }
  }
  ${FRAGMENT_QUOTE_PRODUCT}
`;

export interface QueryReadMotServicingProducts {
  readonly readMotServicingProducts: QuoteProduct[];
}

/**
 * Get all products belong to MOT_SERVICING catalog.
 */
export const QUERY_READ_MOT_SERVICING_PRODUCTS = gql`
  query ReadMotServicingProducts {
    readMotServicingProducts @client {
      ...QuoteProductFragment
    }
  }
  ${FRAGMENT_QUOTE_PRODUCT}
`;

export interface QueryReadAllDisplayableProducts {
  readonly readAllDisplayableProducts: QuoteProduct[];
}

/**
 * Get all products in Customer catalog, Repairs catalog, MOT & Servicing catalog & Diagnostics catalog.
 */
export const QUERY_READ_ALL_DISPLAYABLE_PRODUCTS = gql`
  query ReadAllDisplayableProducts {
    readAllDisplayableProducts @client {
      ...QuoteProductFragment
    }
  }
  ${FRAGMENT_QUOTE_PRODUCT}
`;

export interface QueryReadUpsellProducts {
  readonly readUpsellProducts: QuoteProduct[];
}

/**
 * Get all products in UPSELL catalog.
 */
export const QUERY_READ_UPSELL_PRODUCTS = gql`
  query ReadUpsellProducts {
    readUpsellProducts @client {
      ...QuoteProductFragment
    }
  }
  ${FRAGMENT_QUOTE_PRODUCT}
`;
