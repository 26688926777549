import type { DocumentNode } from '@apollo/client';

/**
 * This is a fantom type just to highlight what type we expect the GQL declaration to have.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-unused-vars
export interface DocumentNodeGQL<T> extends DocumentNode {}

/**
 * Decorator of apollo `gql` function to be able to specify which TS type we are describing.
 */
export const declareGQL = <T>(node: DocumentNode): DocumentNodeGQL<T> => node;
