import { gql } from '@apollo/client';
import type { ISession } from '$apollo/gql/session';
import type { IBooking } from '$apollo/gql/booking';

export interface BookingData {
  readonly collectionAddressId?: IBooking['collectionAddressId'];
  readonly collectionDateTimeLocal?: IBooking['collectionDateTimeLocal'];
  readonly deliveryDateTimeLocal?: IBooking['deliveryDateTimeLocal'];
  readonly collectionDeliveryOption?: IBooking['collectionDeliveryOption'];
  readonly garageId?: IBooking['garageId'];
  readonly bookingId?: IBooking['bookingId'];
  readonly segment: IBooking['segment'];
}

export interface QueryGetBooking {
  readonly getBooking: BookingData;
}

export interface QueryGetBookingVariables {
  readonly bookingId: ISession['bookingId'];
}

export const QUERY_GET_BOOKING = gql`
  query ReadSchedulePageCardsStatus_GetBooking($bookingId: String!) {
    getBooking(bookingId: $bookingId) {
      collectionAddressId
      collectionDateTimeLocal
      deliveryDateTimeLocal
      collectionDeliveryOption
      garageId
      bookingId
      segment
    }
  }
`;
