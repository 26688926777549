import type { TypePolicies } from '@apollo/client';
import type { ReadFooterDataOptions } from '$components/layout/Footer/gql/typePolicies/readFooterData';
import { readFooterData } from '$components/layout/Footer/gql/typePolicies/readFooterData';

export const ssrMainLayoutTypePolicies: TypePolicies = {
  Query: {
    fields: {
      readFooterData: {
        read(_, options) {
          return readFooterData(options as ReadFooterDataOptions);
        },
      },
    },
  },
};
