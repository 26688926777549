import { gql } from '@apollo/client';
import {
  FRAGMENT_IMAGE_FIELD,
  FRAGMENT_CONTENT_FIELD,
  FRAGMENT_LONG_TEXT_FIELD,
  FRAGMENT_SHORT_TEXT_FIELD,
  FRAGMENT_SUB_COMPONENT,
} from '$apollo/gql/cms/contentful';

import type { CmsContentfulComponentCollection } from '$cms/contentful.types';
import { FOOTER_CMS_CONTENT_KEY } from '../../cms';

export interface QueryGetFooterCmsContent {
  getFooterCmsContent: CmsContentfulComponentCollection;
}

export const QUERY_GET_FOOTER_CMS_CONTENT = gql`
  query GetFooterCmsContent($preview: Boolean, $locale: String!) {
    getFooterCmsContent: componentCollection(where: { name: "${FOOTER_CMS_CONTENT_KEY}" }, locale: $locale, limit: 1, preview: $preview) {
      items {
        entriesCollection {
          items {
            ...ShortTextField
            ...SubComponent
          }
        }
      }
    }
    # this component is displayed on all pages, hack to avoid adding it on all
    getPartOfRenaultQuery: component(id: "G619ijV93B9BzfHspWofV", locale: $locale, preview: $preview) {
      entriesCollection {
        items {
          ...ShortTextField
          ...ImgField
        }
      }
    }
  }
  ${FRAGMENT_IMAGE_FIELD}
  ${FRAGMENT_CONTENT_FIELD}
  ${FRAGMENT_LONG_TEXT_FIELD}
  ${FRAGMENT_SHORT_TEXT_FIELD}
  ${FRAGMENT_SUB_COMPONENT}
`;
